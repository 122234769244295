import React, { Component } from "react";
import CacheBuster from "./CacheBuster";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "./components/scrollToTop";
import setUpAxios from "./interceptor";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import LayoutRoute from "./container/LayoutRoute";
import LayoutRouteBlank from "./container/LayoutRouteBlank";
import LoginLayoutRoute from "./container/LoginLayoutRoute";
import Login from "./container/admin/Login/login";
import Register from "./container/admin/Register/register";
import UpdateProfile from "./container/admin/UpdateProfile/UpdateProfile";

import Users from "./container/admin/users/Users";
import ServerDown from "./container/Misl/ServerDown";
import resetPassword from "./container/admin/Login/resetPassword";

// import CustomizeFooter from './container/customize-footer';
import InitialSetup from "./container/trustee/initialSetup/initialSetup";
import AddNewReport from "./container/trustee/addNewReport/addNewReport";
import ServicerDataDB from "./container/trustee/servicerDataDB/servicerDataDB";

import ServicerDataBlockchain from "./container/trustee/servicerDataBlockchain/servicerDataBlockchain";
import GenerateInvestorReport from "./container/trustee/generateInvestorReport/generateInvestorReport";
import ViewInvestorReport from "./container/trustee/viewInvestorReport/viewInvestorReport";
import validateMonthlyInput from "./container/trustee/validateMonthlyInput/validateMonthlyInput";

// ------------------------------Customization---------------------------------------------------------------------------
import CustomizeReport from "./container/trustee/customizereport/customizereport";
// import viewcustomized from './container/trustee/viewcustomized/viewcustomized';
// import  viewport from './container/trustee/viewreport/viewreport';
// import Loanstrat from './container/trustee/loanstrat/loanstrat';
import Dashboard from "./container/trustee/Dasbhoard/Dasbhoard";

import DownloadServicerData from "./container/trustee/DownloadServicerData/DownloadServicerData";
import ViewLoanDataTape from "./container/trustee/ViewLoanDataTape/ViewLoanDataTape";

// IntainABS

import EligiblePortfolioImport from "./container/eligiblePortfolio/eligiblePortfolioImport";
import DueDiligence from "./container/eligiblePortfolio/dueDiligence";
import PoolFormation from "./container/eligiblePortfolio/poolFormation";
import PoolDetails from "./container/eligiblePortfolio/poolDetails";

import PoolList from "./container/loanPoolSetup/poolDetails";
import PoolSummary from "./container/loanPoolSetup/poolSummary";
import BdbPoolSummary from "./container/loanPoolSetup/bdb/bdbPoolSummary";

import PoolSetup from "./container/loanPoolSetup/poolDetails";
import DocumentUpload from "./container/poolTransfer/documentUpload";
import DocumentAttribute from "./container/poolTransfer/documentAttribute";
import DocumentDetails from "./container/poolTransfer/documentDetails";

import BankDetails from "./container/transactionDetails/bankDetails/bankDetails";
import CreateTransaction from "./container/transactionDetails/createTransaction";
import TransactionDetails from "./container/transactionDetails/transactionDetails";

import Collections from "./container/collection/viewCollections";

import SPVDetails from "./container/issuerSPV/issuerspv";
import Tranche from "./container/issuerSPV/tranche/assignedPool";
import PaymentWaterfall from "./container/issuerSPV/paymentWaterfall/paymentWaterfallDetails";

import trancheList from "./container/issuerSPV/investorBuyer/trancheList";
import trancheListBuy from "./container/issuerSPV/investorBuyer/trancheList/trancheListBuy";

import investorReport from "./container/issuerSPV/investorBuyer/investorReport";

import PoolTransferServicer from "./container/poolTransfer/pooltransferservicer";

import preProcessingViewLoans from "./container/preProcessing/preProcessingViewLoans";

import BulkUpload from "./container/preProcessing/bulkupload";

// import initialSetup from './container/reports/initialSetup';
// import addNewReport from './container/reports/addNewReport';
// import servicerDataDB from './container/reports/servicerDataDB';
// import servicerDataBC from './container/reports/servicerDataBlockchain/servicerDataBlockchain';
// import generateInvestorReport from './container/reports/generateInvestorReport/generateInvestorReport';
// import viewInvestorReport from './container/reports/viewInvestorReport/viewInvestorReport';

// import SelectMUI from './container/select';
import viewLoans from "./container/viewLoans";

import AdminDashboard from "./container/admin/Dashboard/dashboard";
import ProcessorDashboard from "./container/processor/dashboard/pdashboard";
import RunDD from "./container/processor/RunDDScreen/rundd";
import DDReport from "./container/processor/DDReportScreen/DDReport";
import processorDashboard from "./container/admin/Processor/processorDashboard";
import fields from "./container/admin/Fields/Fields";
import Certificate from "./container/IssueCertificate/Certificate";
import Status from "./container/admin/Status/status";
// import TemplateTest from './container/processor/IssueCertificate/IssueCertificate'
// import Mapfields from './container/admin/Fields/mapFields';
import ErrorPage from "./components/custom_404";
import { Button } from "@mui/material";

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#048c88",
      light: "#064e4a",
      dark: "#064e4a",
    },
    secondary: {
      main: "#49ae46",
      light: "#d5f2f0",
      dark: "#2e9a2b",
    },
  },
  props: {
    MuiButton: {
      size: "medium",
    },
    MuiFilledInput: {},
    MuiFormControl: {},
    MuiFormHelperText: {},
    MuiIconButton: {
      size: "medium",
    },
    MuiInputBase: {},
    MuiInputLabel: {},
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {},
    MuiFab: {
      size: "medium",
    },
    MuiTable: {
      size: "medium",
    },
    MuiTextField: {
      variant: "filled",
      size: "medium",
    },
    MuiToolbar: {
      variant: "dense",
    },
  },

  overrides: {},
});

class App extends Component {
  render() {
    setUpAxios();
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          return (
            <>
              {loading && <p style={{ textAlign: "center" }}>Loading...</p>}
              {!loading && !isLatestVersion && (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ background: "#048c88" }}
                    onClick={refreshCacheAndReload}
                  >
                    Update Available - Click to Refresh
                  </Button>
                </div>
              )}
              {!loading && isLatestVersion && (
                <div className="App">
                  <ThemeProvider theme={theme}>
                    <SnackbarProvider
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      maxSnack={3}
                    >
                      <Router history={history}>
                        <ScrollToTop />
                        <Switch>
                          <LoginLayoutRoute
                            exact={true}
                            path="/"
                            component={Login}
                          />
                          <LoginLayoutRoute
                            exact={true}
                            path="/register"
                            component={Register}
                          />
                          <LoginLayoutRoute
                            exact={true}
                            path="/resetPassword"
                            component={resetPassword}
                          />

                          <LoginLayoutRoute
                            exact={true}
                            path="/update-profile"
                            component={UpdateProfile}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Users"}
                            path="/admin/users/:orgname/:status"
                            component={Users}
                          />

                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/dashboard"
                            component={Dashboard}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/initial-setup/:DealId?/:DealMonth?/:DealYear?"
                            component={InitialSetup}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/add-new/:DealId?/:DealMonth?/:DealYear?"
                            component={AddNewReport}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/view-servicer-mongodb-data/:DealId?/:DealMonth?/:DealYear?"
                            component={ServicerDataDB}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/validate-monthly-inputs/:DealId?/:DealMonth?/:DealYear?"
                            component={validateMonthlyInput}
                          />

                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/view-servicer-blockchain-data/:DealId?/:DealMonth?/:DealYear?"
                            component={ServicerDataBlockchain}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/generate-investor-report/:DealId?/:DealMonth?/:DealYear?"
                            component={GenerateInvestorReport}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/customize-investor-report/:DealId?/:DealMonth?/:DealYear?"
                            component={CustomizeReport}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/view-investor-report/:DealId?/:DealMonth?/:DealYear?"
                            component={ViewInvestorReport}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/download-servicer-data/:DealId?/:DealMonth?/:DealYear?"
                            component={DownloadServicerData}
                          />
                          <LayoutRoute
                            exact={true}
                            pageTitle={"Report"}
                            path="/report/:DealType/view-loan-data-tape/:type?/:DealId?/:DealMonth?/:DealYear?/:groupBy?"
                            component={ViewLoanDataTape}
                          />
                          <LayoutRouteBlank
                            exact={true}
                            pageTitle={"ServerDown"}
                            path="/server-down"
                            component={ServerDown}
                          />

                          <LayoutRoute
                            exact
                            path="/portfolio-setup-wizard/eligible-portfolio-import"
                            component={EligiblePortfolioImport}
                          />
                          <LayoutRoute
                            exact
                            path="/portfolio-setup-wizard/due-diligence"
                            component={DueDiligence}
                          />
                          <LayoutRoute
                            exact
                            path="/portfolio-setup-wizard/pool-formation"
                            component={PoolFormation}
                          />
                          <LayoutRoute
                            exact
                            path="/portfolio-setup-wizard/pool-details/:id"
                            component={PoolDetails}
                          />

                          <LayoutRoute
                            exact
                            path="/loan-pool-setup/pool-summary"
                            component={PoolSummary}
                          />
                          <LayoutRoute
                            exact
                            path="/loan-transfer/pool-details"
                            component={PoolSetup}
                          />
                          <LayoutRoute
                            exact
                            path="/loan-transfer/document-upload"
                            component={DocumentUpload}
                          />
                          <LayoutRoute
                            exact
                            path="/loan-transfer/document-attribute"
                            component={DocumentAttribute}
                          />
                          <LayoutRoute
                            exact
                            path="/loan-transfer/document-details"
                            component={DocumentDetails}
                          />
                          <LayoutRoute
                            exact
                            path="/transaction-details/bank-details"
                            component={BankDetails}
                          />
                          <LayoutRoute
                            exact
                            path="/transaction-details/create-transaction"
                            component={CreateTransaction}
                          />
                          <LayoutRoute
                            exact
                            path="/transaction-details/payment-details"
                            component={TransactionDetails}
                          />
                          <LayoutRoute
                            exact
                            path="/pool-details"
                            component={PoolList}
                          />
                          <LayoutRoute
                            exact
                            path="/pool-details/view-collections/:id"
                            component={Collections}
                          />
                          <LayoutRoute
                            exact
                            path="/issuer-spv/spv-details"
                            component={SPVDetails}
                          />
                          <LayoutRoute
                            exact
                            path="/issuer-spv/tranche"
                            component={Tranche}
                          />
                          <LayoutRoute
                            exact
                            path="/issuer-spv/payment-waterfall"
                            component={PaymentWaterfall}
                          />
                          <LayoutRoute
                            exact
                            path="/investorBuyer/tranche-list"
                            component={trancheList}
                          />
                          <LayoutRoute
                            exact
                            path="/investorBuyer/tranche-list-buy/:id"
                            component={trancheListBuy}
                          />
                          {/* <LayoutRoute exact path="/investorBuyer/investorreport" component={viewInvestorReport} /> */}
                          <LayoutRoute
                            exact
                            path="/poolTransfer/pooltransferservicer"
                            component={PoolTransferServicer}
                          />
                          <LayoutRoute
                            exact
                            path="/viewloans/:id"
                            component={viewLoans}
                          />
                          <LayoutRoute
                            exact
                            path="/viewloans"
                            component={viewLoans}
                          />
                          <LayoutRoute
                            exact
                            path="/preprocessingviewloans"
                            component={preProcessingViewLoans}
                          />
                          <LayoutRoute
                            exact
                            path="/bulkupload"
                            component={BulkUpload}
                          />
                          <LayoutRoute
                            exact
                            path="/poolsummary/:id"
                            component={PoolSummary}
                          />
                          <LayoutRoute
                            exact
                            path="/pool-data-analytics/:id"
                            component={BdbPoolSummary}
                          />

                          <LayoutRoute
                            exact
                            path="/admin/dashboard"
                            component={AdminDashboard}
                          />
                          <LayoutRoute
                            exact
                            path="/admin/processordashboard"
                            component={processorDashboard}
                          />
                          <LayoutRoute
                            exact
                            path="/admin/fields"
                            component={fields}
                          />
                          <LayoutRoute
                            exact
                            path="/processor/Certificate"
                            component={Certificate}
                          />

                          {/* <LayoutRoute exact path="/admin/mapfields" component={Mapfields} /> */}
                          <LayoutRoute
                            exact
                            path="/processor/dashboard"
                            component={ProcessorDashboard}
                          />
                          <LayoutRoute
                            exact
                            path="/processor/rundd/:id/:name"
                            component={RunDD}
                          />
                          {/* <LayoutRoute exact path="/issuecerticate" component={TemplateTest} /> */}
                          <LayoutRoute
                            exact
                            path="/ddreport"
                            component={DDReport}
                          />
                          <LayoutRoute
                            exact
                            path="/status"
                            component={Status}
                          />
                          <LayoutRouteBlank
                            exact={true}
                            path="*"
                            component={ErrorPage}
                          />

                          {/* <LayoutRoute exact path="/review" component={Review} /> */}

                          {/* <LayoutRoute exact path="/reports/initialsetup" component={initialSetup} />
              <LayoutRoute exact path="/reports/addnewreport/:dealid?" component={addNewReport} />              
              <LayoutRoute exact path="/reports/servicerdatadb/:dealid?/:month?/:year?" component={servicerDataDB} />
              <LayoutRoute exact path="/reports/servicerdatablockchain/:dealid?/:month?/:year?" component={servicerDataBC} />
              <LayoutRoute exact path="/reports/generateinvestorreport/:dealid?/:month?/:year?" component={generateInvestorReport} />
              <LayoutRoute exact path="/reports/viewinvestorreport/:dealid?/:month?/:year?" component={viewInvestorReport} /> */}
                        </Switch>
                      </Router>
                    </SnackbarProvider>
                  </ThemeProvider>
                </div>
              )}
            </>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
