// import React, { Component } from 'react';
// import TextField from '@material-ui/core/TextField';
// import match from '../../images/match.png';
// import mismatch from '../../images/mismatch.png';
// import * as moment from 'moment';
// import Tooltip from '@material-ui/core/Tooltip';

// function myFunction(str) {
//     //var str = "Hello world!";
//     if (str.length > 2) {
//         var res = str.substring(2, str.length);
//         var rep = res.replace(/[a-z]/gi, 'x')
//         return str.replace(res, rep);
//     }

//     return str;
// }

// export default class CompareItem extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             fields: {
//                 highlights: this.props.highlights,
//                 lmsLoan: this.props.lmsLoan,
//                 agreementLoan: this.props.agreementLoan,
//                 match: this.props.match,
//             },

//         };
//         this.handleChange = this.handleChange.bind(this);
//     }

//     //  arr=localStorage.getItem('fieldss') === null ? [] : JSON.parse(localStorage.getItem("fieldss"));

//     handleChange(e) {
//         console.log('arrrrrr', this.arr)
//         const fields = this.state.fields;
//         fields[e.target.name] = e.target.value;
//         this.setState({
//             fields: fields
//         });
//         console.log('fields', this.state.fields, this.props.title);
//         let x = this.props.title
//         let local = localStorage.getItem('fieldss') === null ? {} : JSON.parse(localStorage.getItem("fieldss"))
//         local[x] = this.state.fields
//         // this.arr.push([this.props.title,this.state.fields.lmsLoan,this.state.fields.agreementLoan])
//         localStorage.setItem('fieldss', JSON.stringify(local))
//     }

//     componentDidMount() {
//         console.log(this.props.title + "-" + this.props.lmsLoan + "-" + this.state.fields.agreementLoan)
//     }
//     componentWillReceiveProps(nextProps) {
//         this.setState({ fields: { ...this.state.fields, highlights: nextProps.highlights } });
//     }
//     // shouldComponentUpdate(nextProps, nextState) {
//     //     if (this.state.fields.highlights.length !== nextProps.length) return true
//     // }
//     render() {
//         console.log("CompareItem Child", this.state.fields)
//         return (
//             <React.Fragment>
//                 {/* <tr>
//                     <td colSpan="3">{this.props.title}</td>
//                 </tr> */}
//                 <tr>
//                     <td>
//                         <Tooltip title={this.state.fields.lmsLoan}>

//                             <React.Fragment>
//                                 <label>{this.props.title}</label>
//                                 <TextField
//                                     onClick={this.props.onClick}
//                                     // label={this.props.title}
//                                     id={"outlined-basic-lmsLoan" + this.props.id}
//                                     name="lmsLoan"
//                                     value={this.props.mask == false ? this.state.fields.lmsLoan : myFunction(this.state.fields.lmsLoan)}
//                                     onChange={this.handleChange}
//                                     variant="outlined"
//                                     size="small"
//                                 // disabled={(this.state.fields.match === 1 || this.state.fields.lmsLoan == this.state.fields.agreementLoan) ? true : false}
//                                 // disabled={this.state.fields.agreementLoan?.length == 0 ? true : this.state.fields.agreementLoan === this.state.fields.lmsLoan ? true : false}
//                                 // disabled
//                                 />
//                             </React.Fragment>
//                         </Tooltip>
//                     </td>
//                     <td>
//                         <Tooltip title={this.state.fields.lmsLoan}>
//                             <React.Fragment>
//                                 <label>{this.props.title}</label>
//                                 <TextField
//                                     onClick={this.props.onClick}
//                                     // label={this.props.title}
//                                     id={"outlined-basic-agreementLoan" + this.props.id}
//                                     name="agreementLoan"
//                                     value={this.props.mask == false ? this.state.fields.agreementLoan : myFunction(this.state.fields.agreementLoan)}
//                                     onChange={this.handleChange}
//                                     variant="outlined"
//                                     size="small"
//                                 // disabled={(this.state.fields.match === 1 || this.state.fields.lmsLoan == this.state.fields.agreementLoan) ? true : false }
//                                 // disabled={this.state.fields.agreementLoan?.length == 0 ? true : this.state.fields.agreementLoan === this.state.fields.lmsLoan ? true : false}
//                                 // disabled
//                                 />
//                             </React.Fragment>
//                         </Tooltip>
//                     </td>
//                     <td>
//                         <span id="lmsLoanContractNumberIcon" ></span>
//                         {this.state.fields.agreementLoan?.length == 0 ? <img src={mismatch} /> : (this.state.fields.match === 1 || this.state.fields.lmsLoan == this.state.fields.agreementLoan) ? <img src={match} /> : <img src={mismatch} />}
//                         {/* {this.state.fields.agreementLoan?.length == 0 ? <img src={match} /> : this.state.fields.agreementLoan === this.state.fields.lmsLoan ? <img src={match} /> : <img src={mismatch} />} */}

//                     </td>
//                 </tr>
//             </React.Fragment>
//         )
//     }
// }

import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import matchIcon from '../../images/match.png';
import mismatchIcon from '../../images/mismatch.png';
import * as moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

function myFunction(str) {
    //var str = "Hello world!";
    if (str.length > 2) {
        var res = str.substring(2, str.length);
        var rep = res.replace(/[a-z]/gi, 'x');
        return str.replace(res, rep);
    }

    return str;
}
const updateHash = (highlight) => {
    if (highlight.position.pageNumber == 0) return;
    document.location.hash = `highlight-${highlight.id}`;
};
export default class CompareItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                // highlights: this.props.highlights,
                // lmsLoan: this.props.lmsLoan,
                // agreementLoan: this.props.agreementLoan,
                // match: this.props.match,
                highlights: this.props.highlights,
                lmsLoan: JSON.parse(sessionStorage.getItem('activeLoanData'))?.lmsloan,
                agreementLoan: JSON.parse(sessionStorage.getItem('activeLoanData'))?.agreementloan,
                match: this.props.match,
                isMatched: this.props.isMatched,
            },
            edit: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    //  arr=localStorage.getItem('fieldss') === null ? [] : JSON.parse(localStorage.getItem("fieldss"));

    handleChange(highlight, event, value, key) {
        this.setState({
          fields: {
            ...this.state.fields,
            highlights: this.state.fields.highlights.map((e) => {
              if (e.id == highlight.id) {
                if (key === "LMS") {
                  e.content.lmsloan[value] = event.target.value;
                } else {
                  e.content.agreementloan[value] = event.target.value;
                }
              }
              return e;
            }),
          },
          edit: true, // Set edit state here
        });
      }
      

    handleEdit(highlight, event, value, key){
      if(Object.values(highlight.content.lmsloan)[0] ===
      Object.values(highlight.content.agreementloan)[0]){
        console.log(highlight,'handleedit');
        return <img src={matchIcon} />;
      } else {
        console.log(highlight,'handleeditt');
        return <img src={mismatchIcon} />;
      }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            fields: { ...this.state.fields, highlights: nextProps.highlights },
        });
    }
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.fields.highlights.length !== nextProps.length) return true
    // }

    renderMatchMismatchIcons = (highlight, idx) => {
        const { match, agreementLoan, lmsLoan } = this.state.fields;
        const isEditing = this.state.edit;
      
        console.log(
          "renderMatchMismatchIcons",
          Object.keys(agreementLoan),
          match[Object.keys(agreementLoan)[idx]]
        );
      
        if (!isEditing) {
          return match[Object.keys(lmsLoan)[idx]] === 1 ? (
            <img src={matchIcon} />
          ) : (
            <img src={mismatchIcon} />
          );
        }
      
        return Object.values(highlight.content.lmsloan)[0] ===
          Object.values(highlight.content.agreementloan)[0] ||
          match[Object.keys(lmsLoan)[idx]] === 1 ? (
          <img src={matchIcon} />
        ) : (
          <img src={mismatchIcon} />
        );
      };
      
    render() {
        const { highlights } = this.state.fields;
        console.log('CompareItem Child', { highlights, fields: this.state.fields, isMatched: this.props.isMatched });
        // return null
        return (
            <React.Fragment>
                {/* <tr>
                    <td colSpan="3">{this.props.title}</td>
                </tr> */}
                <tr>
                    {/* <td>
                        {highlights.map(highlight => {
                            return <Tooltip title={Object.keys(highlight.content.lmsloan)[0]}>
                                <React.Fragment>
                                    <label>{Object.keys(highlight.content.lmsloan)[0]}</label>
                                    <TextField
                                        onClick={() => updateHash(highlight)}
                                        // label={this.props.title}
                                        id={'outlined-basic-lmsLoan' + this.props.id}
                                        name='lmsLoan'
                                        //   value={
                                        //     this.props.mask == false
                                        //       ? this.state.fields.lmsLoan
                                        //       : myFunction(this.state.fields.lmsLoan)
                                        //   }
                                        defaultValue={Object.values(highlight.content.lmsloan)[0]}
                                        onChange={this.handleChange}
                                        variant='outlined'
                                        size='small'
                                    />
                                </React.Fragment>
                            </Tooltip>
                        })}
                    </td> */}
                    <td>
                        {highlights.map((highlight, idx) => {
                            return (
                                <div className='d-flex justify-content-between algin-items-center gap-2'>
                                    <Tooltip title={Object.keys(highlight.content.lmsloan)[0]}>
                                        <React.Fragment>
                                            <div>
                                                <label>{Object.keys(highlight.content.lmsloan)[0]}</label>
                                                <TextField
                                                    onClick={() => updateHash(highlight)}
                                                    // label={this.props.title}
                                                    id={'outlined-basic-lmsLoan' + this.props.id}
                                                    name='lmsLoan'
                                                    //   value={
                                                    //     this.props.mask == false
                                                    //       ? this.state.fields.lmsLoan
                                                    //       : myFunction(this.state.fields.lmsLoan)
                                                    //   }
                                                    value={
                                                        Object.values(highlight.content.lmsloan)[0]
                                                    }
                                                    onChange={(e) => {
                                                        this.handleChange(highlight, e,
                                                        Object.keys(highlight.content.lmsloan)[0], "LMS");
                                                        this.handleEdit(highlight, e,
                                                        Object.keys(highlight.content.lmsloan)[0], "LMS");
                                                    }}
                                                    variant='outlined'
                                                    size='small'
                                                />
                                            </div>
                                        </React.Fragment>
                                    </Tooltip>

                                    <Tooltip title={Object.keys(highlight.content.lmsloan)[0]}>
                                        <React.Fragment>
                                            <div className='mx-2'>
                                                <label >{Object.keys(highlight.content.lmsloan)[0]}</label>
                                                <div className='d-flex align-items-center'>
                                                    <TextField
                                                        onClick={() => updateHash(highlight)}
                                                        // label={this.props.title}
                                                        id={'outlined-basic-agreementLoan' + this.props.id}
                                                        name='agreementLoan'
                                                        //   value={
                                                        //     this.props.mask == false
                                                        //       ? this.state.fields.agreementLoan
                                                        //       : myFunction(this.state.fields.agreementLoan)
                                                        //   }
                                                        value={
                                                            Object.values(highlight.content.agreementloan)[0]
                                                        }
                                                        onChange={
                                                            (e) => {this.handleChange(highlight, e, Object.keys(highlight.content.agreementloan)[0], "CONTRACT");
                                                                    this.handleEdit(highlight, e, Object.keys(highlight.content.agreementloan)[0], "CONTRACT");
                                                        }}
                                                        variant='outlined'
                                                        size='small'
                                                    />
                                                    <span className='ml-2'>
                                                        {/* {Object.values(highlight.content.agreementloan)[0]
                                                            ?.length == 0 ? (
                                                            <img src={mismatch} />
                                                        ) : match[idx] === 1 ||
                                                            Object.values(highlight.content.lmsloan)[0] ==
                                                            Object.values(
                                                                highlight.content.agreementloan
                                                            )[0] ? (
                                                            <img src={match} />
                                                        ) : (
                                                            <img src={mismatch} />
                                                        )} */}
                                                        {this.renderMatchMismatchIcons(highlight, idx)}
                                                        {/* {this.state.fields.match[Object.keys(this.state.fields.agreementLoan)[idx]] === 1 ||
                                                            Object.values(highlight.content.lmsloan)[0] ==
                                                            Object.values(
                                                                highlight.content.agreementloan
                                                            )[0]
                                                            ? <img src={match} /> : this.state.fields.agreementLoan[Object.keys(this.state.fields.agreementLoan)[idx]]?.length == 0 ? <img src={mismatch} /> : <img src={mismatch} />} */}
                                                    </span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </td>
                    {/* <td>
                        <span id='lmsLoanContractNumberIcon'></span>
                        {highlights.map((highlight, idx) => {
                            return <>
                                {Object.values(highlight.content.agreementloan)[0]?.length == 0 ? (
                                    <img src={mismatch} />
                                ) : match[idx] === 1 ||
                                    Object.values(highlight.content.lmsloan)[0] == Object.values(highlight.content.agreementloan)[0] ? (
                                    <img src={match} />
                                ) : (
                                    <img src={mismatch} />
                                )}
                            </>
                        })}
                    </td> */}
                </tr>
            </React.Fragment>
        );
    }
}
